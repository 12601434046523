.NavbarItems {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;

 
}

.navbar-logo {
 color: #fff;
 justify-self: start;
 margin-left: 20px;
 cursor: pointer;

}

.logo{
width: 30vh !important;
height: 3vh !important;
float: left;
position: relative;
margin: 25px 15px 15px 10px;

}

.fa-react {
 margin-left: 0.5rem;
 font-size: 1.6rem;
}

.nav-menu {
 display: grid;
 grid-template-columns: repeat(5, auto);
 grid-gap: 10px;
 list-style: none;
 text-align: center;
 width: 70vw;
 justify-content: end;
 margin-right: 2rem;
 margin-top: 20px;
}

.nav-links {
 color: white;
 text-decoration: none;
 padding: 0.5rem 1rem;
}

.nav-links:hover {
 background-color: black;
 border-radius: 4px;
 transition: all 0.2s ease-out;
}

.fa-bars {
 color: #fff;
}

.nav-links-mobile {
 display: none;
}

.menu-icon {
 display: none;
}

@media screen and (max-width: 960px) {
 .NavbarItems {
     position: relative;
 }

 .nav-menu {
     display: flex;
     flex-direction: column;
     width: 100%;
     height: 500px;
     position: absolute;
     top: 61px;
     left: -100%;
     opacity: 0;
     transition: all 0.1s ease;
 }

 .nav-menu.active {
      background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
     left: 0;
     opacity: 1;
     transition: all 0.5s ease;
    
     z-index: 999;
 }

 .nav-links {
     text-align: center;
     padding: 2rem;
     width: 100%;
     display: table;
 }

 .nav-links:hover {
     background-color:black;
     border-radius: 0;
 }

 .navbar-logo {
     position: absolute;
     top: 0;
     left: 0;
     transform: translate(21%, 10%);
 }

 .menu-icon {
     display: block;
     position: absolute;
     top: 0;
     right: 0;
     transform: translate(-100%, 60%);
     font-size: 1.8rem;
     cursor: pointer;
 }

 .fa-times {
     color: #fff;
     font-size: 2rem;
 }

 .nav-links-mobile {
     display: block;
     text-align: center;
     padding: 1.5rem;
     margin: 2rem auto;
     border-radius: 4px;
     width: 80%;
     background: #4ad9e4;
     text-decoration: none;
     color: #fff;
     font-size: 1.5rem;
 }

 .nav-links-mobile:hover {
     background: #fff;
     color: black;
     transition: 250ms;
 }

 Button {
     display: none;
 }
}
@media screen and (width: 1024px) {
    .NavbarItems {
        position: relative;
    }
   
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 61px;
        left: -100%;
        opacity: 0;
        transition: all 0.1s ease;
    }
   
    .nav-menu.active {
         background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
       
        z-index: 999;
    }
   
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
   
    .nav-links:hover {
        background-color:black;
        border-radius: 0;
    }
   
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(21%, 10%);
    }
   
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
   
    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
   
    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }
   
    .nav-links-mobile:hover {
        background: #fff;
        color: black;
        transition: 250ms;
    }
   
    Button {
        display: none;
    }
   }
   
   
