.whts{

    position:fixed;
    margin-top: 550px;
    margin-left: 30px;
    width: 30vh !important;
    height: 30px;
    z-index: 10;
    
        
      
}
@media screen and (max-width: 960px) {
    .whts {
      margin-top: 450px;
    }
}
  