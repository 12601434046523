.officeimg{
    width:100vh !important;
    height: 50vh; 
    margin-top: 50px;
    margin-left: 400px;
  }

  @media screen and (max-width: 991px) {
    .officeimg {
      margin-left: 3vh;
      width:60vh !important;
      height: 50vh !important;
   
      
    }
  }
  
  @media screen and (max-width: 768px) {
    .officeimg {
      margin-left: 3vh;
      margin-top: 10px;
      }
    }
    @media only screen and (max-width: 1024px) {
      .officeimg {
        margin-left: -1vh;
        width:60vh !important;
        height: 50vh !important;
      }
    }    




    .App {
      text-align: center;
    }
     
    .App-logo {
      height: 40vmin;
    }
     
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }
     
    .App-link {
      color: #09d3ac;
    }
     

     
    .slides .slick-prev,
    .slides .slick-next {
      position: absolute;
      top: 50%;
      z-index: 1;
    }
    
    
    .slides {
      position: relative;
    }
    
    .slides .slick-prev,
    .slides .slick-next {
      position: absolute;
      top: 50%;
      z-index:1;
    }
    
    .slides .slick-prev {
      left: 27%;
    }
    
    .slides .slick-next {
      right: 28%;
    }