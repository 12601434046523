@media screen and (min-width: 480px) {

    /***************************************
    Two Column layout
    ****************************************/
      #primary {
        width: 50%;
        float: left;
      }
    
      #secondary {
        width: 40%;
        float: right;
      }
    
    /***************************************
    Page: Portfolio 
    ****************************************/
    
    #gallery li{
      width: 28.3333%;
    }
    
    #gallery li:nth-child(4n) {
        clear: left;
      }
    
    /***************************************
    Page: About
    ****************************************/
    
      .profile-photo {
        float: left;
        margin: 0 5% 80px o;
    }
    
    }
    @media screen and (min-width: 660px) {
    
    }
    general
****************************************/

body { 
  font-family: 'Open Sans', sans-serif;
}

#wrapper {
  max-width: 940px;
  margin: 0 auto;
  padding: 0 5%;
}




/***************************************
Heading
****************************************/



#logo {
  text-align:center;
  margin: 0; 
}


h2 {
  Font-size: 0.75em;
  margin: -5px 0 0;
  font-weight: normal;
}

h1{
  margin: 0 0 1em 0;
}




footer {
  font-size: 0.75em;
  text-align: center;
  clear: both;
  padding-top: 50px;
  color: #ccc;
}

.social-icon { 
  width: 20px;
  height: 20px;
  margin: 0 5px;
}


#gallery {
  margin: 0;
  padding: 0;
  list-style: none;
}

#gallery li {
  float: left;
  width: 45%;
  margin: 2.5%;
  background-color: #f5f5f5;
  color: #bdc37c;
}

#gallery li a p {
  margin: 0;
  padding: 5%;
  Font-size: 0.75em;
  color: #bdc37c;
}


**/

.profile-photo {
  display: block;
  max-width: 150px;
  margin: 0 auto 30px;
  border-radius: 20px;
}
