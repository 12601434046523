
img {
    
        width: 100%;
        height: 90vh;
        padding: 0;
        margin-right: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-align: left;
    
}

       

  h2 {
    
        position: relative;
        text-align: center;
        color: black;
        display: inline-block; 
        margin-top: -100px;
        text-align: left;
      }


   .heading{
       color: #ffffff;
       position: absolute;
       top: 50%;
       text-align: center;
       font-size: 3rem;
       text-shadow: 5px 5px 10px #000000;
       text-transform: uppercase;


   }   


   .time { 
    position: absolute; 
    top: 300px; 
    left: 0; 
    width: 100%; 
    margin-left: 12vh;
    margin-top: -100px;
    text-shadow: 2px 2px #0c0c0c;
    
  }
  
  .time { 
     color: white; 
     font: bold 24px/45px Helvetica, Sans-Serif; 
     letter-spacing: 1px;
     font-weight: 700;
     font-size: 40px;
     padding: 10px; 
  }
 
  @media screen and (max-width: 991px) {
    .time {
      margin-left: 1vh;
      margin-top: -231px;
      text-shadow: 2px 2px #0c0c0c;
      
    }
    img{
      width:100%;
      height: 313px;
    }
    
  }
  
  @media screen and (max-width: 768px) {
    .time {
      margin-left: 1vh;
      margin-top: -231px;
      text-shadow: 2px 2px #0c0c0c;
      
    }
    }
