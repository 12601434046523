


    .slides .slick-prev,
    .slides .slick-next {
      position: absolute;
      top: 50%;
      z-index: 1;
    }


    .slides {
      position: relative;
    }

    .slides .slick-prev,
    .slides .slick-next {
      position: absolute;
      top: 80%;
      z-index:1;
    }

   .slides .slick-prev {
     left: 5%;
   }
   

   .slides .slick-next {
     right: 5%;
   }


   .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 36px;
    line-height: 1;
    opacity: .75;
    color: black;
   }